<template>
    <Dropdown v-model="selected" :choices="choices" placeholder="" />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import TabContentViewTabType from '~/types/TabContentViewTabType';

const emit = defineEmits(['update:model-value']);

const props = defineProps<{
    tabs: TabContentViewTabType[];
    slug?: string | null;
}>();

const selected = ref<string | null>(props.slug);

const choices: any[] = props.tabs.map((tab: TabContentViewTabType) => {
    return {
        value: tab.slug,
        label: tab.label,
    };
});

watch(
    () => selected.value,
    () => {
        emit('update:model-value', selected.value);
    },
);
</script>
